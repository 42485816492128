import React from "react"
import Layout from "../components/Layout"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Emoji from "a11y-react-emoji"
import { Twitter, Unity, Skyscanner, Birdi, ClickTravel } from "../components/Links"

import "../styles/style.css"

// using gatsby-image to pre-process the picture
// https://www.gatsbyjs.com/docs/using-gatsby-image/
export const query = graphql`
  query HomeImageQuery {
    file(relativePath: { eq: "images/headshots_with_alpha_gutter-compressor.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default function Profile({ data }) {
  return (
    <Layout>
      <main>
        <section className="page-callout">
          <h1>
            Hi, I'm Iain <Emoji symbol="👋" label="wave" />
          </h1>
        </section>

        <section>
          <div className="description">
            <p>
              I'm an Engineering Manager working at <Unity />. Previously, I've worked at <ClickTravel /> as a Senior
              Software Engineer, <Skyscanner /> as a Software Engineer in Growth and with <Birdi /> as an Agile Coach.
            </p>

            <section className="profile-image flat-shadow">
              <Img fluid={data.file.childImageSharp.fluid} alt="Various pictures of Iain Pritchard, owned by him." />
            </section>

            <p>
              I care deeply about people and tech. I'm enjoying this opportunity to build a great team, to set them up
              for success and to give them opportunities to grow and develop.
            </p>

            <p>
              You'll likely find me listening to music (at home or at a concert), playing music (I sing and play guitar)
              or scrolling and posting on <Twitter>Twitter</Twitter>.
            </p>

            <p>
              Lately, I've found myself exploring <Link to="/photos">photography</Link>.
            </p>
          </div>
        </section>
      </main>
    </Layout>
  )
}
